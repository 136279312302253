import logo from './logo.svg';
// import './App.css';
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
registerCoreBlocks();
const App = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            
            {
              name: "short-text",
              id: "kd12edg",
              attributes: {
              description: "tesT",
	        classnames: "first-block",
                required: true,
                label: "Let's start with your name"
              }
            },
            {
              name: "multiple-choice",
              id: "gqr1294c",
              attributes: {
                required: true,
                multiple: true,
                verticalAlign: false,
                label: "Which subjects do you love the most?",
                choices: [
                  {
                    label: "Physics",
                    value: "physics"
                  },
                  {
                    label: "Math",
                    value: "math"
                  },
                  {
                    label: "English",
                    value: "english"
                  },
                  {
                    label: "Biology",
                    value: "biology"
                  }
                ]
              }
            },
            {
              name: "slider",
              id: "gqr1294ca",
              attributes: {
                required: true,
                label: "Which subjects do you love the most?",
                min: 1,
                max: 7, 
                step: 1
                
              }
            },
            {
              name: "multiple-choice",
              id: "gqr1294caa",
              attributes: {
                required: true,
                multiple: true,
                verticalAlign: false,
                label: "Which subjects do you love the most?",
                choices: [
                  {
                    label: "1",
                    value: "1"
                  },
                  {
                    label: "2",
                    value: "2"
                  },
                  {
                    label: "3",
                    value: "3"
                  },
                  {
                    label: "4",
                    value: "4"
                  }
                ]
              }
            },
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
          // setTimeout(() => {
          //   setIsSubmitting(false);
          //   completeForm();
          // }, 500);
          console.log(data)
        }}
      />
    </div>
      )
}

export default App;
